<template>
  <div
    class="
      sm:h-72
      h-176
      text-100
      bg-secondary-900
      flex flex-col
      justify-between
    "
  >
    <div class="w-mobile sm:w-median lg:w-large mx-auto">
      <div
        class="
          grid
          sm:grid-cols-12
          grid-cols-5
          gap-4
          sm:gap-3.5
          lg:gap-4
          text-textLight
          mt-8
        "
      >
        <!-- Logo -->
        <div class="col-span-2">
          <div class="flex flex-col justify-items-center">
            <img
              @click="gotToHome"
              src="@/assets/images/logo.png"
              class="cursor-pointer object-contain"
              alt="Footer Logo"
              style="height: 200px !important"
            />
           
          </div>
        </div>

        <!-- Rubrique Boutique -->
        <div
          class="
            sm:col-span-2
            col-span-2
            grid grid-cols-1
            gap-4
            sm:gap-3.5
            lg:gap-4
          "
        >
          <div class="mb-6">
            <ps-label
              class="font-medium mb-4 lg:text-2xl text-xl"
              textColor="text-textLight"
              textSize="font-light lg:text-sm text-xs"
              >{{ $t("footer__shop") }}</ps-label
            >
            <ps-label class="mt-2 font-light lg:text-sm text-xs">
              <ps-route-link
                :to="{ name: 'category-list' }"
                textColor="text-textLight"
                textSize="font-light lg:text-sm text-xs"
                >{{ $t("footer__categories") }}</ps-route-link
              >
            </ps-label>
            <ps-label
              class="mt-2 font-light lg:text-sm text-xs cursor-pointer"
              @click="viewAllRecentItemList"
              textColor="text-textLight"
              textSize="font-light lg:text-sm text-xs"
              >{{ $t("footer__latest_posts") }}</ps-label
            >
            <ps-label
              class="my-2 font-light lg:text-sm text-xs cursor-pointer"
              @click="viewAllPopularItemList"
              textColor="text-textLight"
              textSize="font-light lg:text-sm text-xs"
              >{{ $t("footer__popular_posts") }}</ps-label
            >
          </div>
        </div>

        <!-- Support -->
        <div
          class="
            sm:col-span-2
            col-span-3
            grid grid-cols-1
            gap-4
            sm:gap-3.5
            lg:gap-4
          "
        >
          <div class="mb-6">
            <ps-label
              class="font-medium mb-4 lg:text-2xl text-xl"
              textColor="text-textLight"
              textSize="font-light lg:text-sm text-xs"
              >{{ $t("footer__support") }}</ps-label
            >
            <ps-label class="mt-2 font-light lg:text-sm text-xs">
              <ps-route-link
                :to="{ name: 'contact' }"
                textColor="text-textLight"
                textSize="font-light lg:text-sm text-xs"
                >{{ $t("footer__contact_us") }}</ps-route-link
              >
            </ps-label>
            <div class="flex items-center">
              <ps-icon
                textColor="text-primary-accent dark:text-primary-accent"
                name="whatsapp"
                h="20"
                w="20"
                class="text-primary-accent me-1"
              />
              <ps-label class="mt-2 font-light lg:text-sm text-xs">
                <a
                  href="https://api.whatsapp.com/send?phone=+22898175470&text=Bonjour"
                  target="_blank"
                  style="color: white"
                  >+228 98 17 54 70 (Service client H24)</a
                >
              </ps-label>
            </div>
          </div>
        </div>

        <!-- Company -->
        <div
          class="
            sm:col-span-2
            col-span-2
            grid grid-cols-1
            gap-4
            sm:gap-3.5
            lg:gap-4
          "
        >
          <div class="mb-6">
            <ps-label
              class="font-medium mb-4 lg:text-2xl text-xl"
              textColor="text-textLight"
              textSize="font-light lg:text-sm text-xs"
              >{{ $t("footer__company") }}</ps-label
            >
            <ps-label class="mt-2 font-light lg:text-sm text-xs">
              <ps-route-link
                :to="{ name: 'blog-list' }"
                textColor="text-textLight"
                textSize="font-light lg:text-sm text-xs"
                >{{ $t("footer__blogs") }}</ps-route-link
              >
            </ps-label>
            <ps-label class="mt-2 font-light lg:text-sm text-xs">
              <ps-route-link
                :to="{ name: 'about' }"
                textColor="text-textLight"
                textSize="font-light lg:text-sm text-xs"
                >{{ $t("footer__about_us") }}</ps-route-link
              >
            </ps-label>
            <ps-label class="mt-2 font-light lg:text-sm text-xs">
              <ps-route-link
                :to="{ name: 'privacy' }"
                textColor="text-textLight"
                textSize="font-light lg:text-sm text-xs"
                >{{ $t("privact_modal__privacy_policy") }}</ps-route-link
              >
            </ps-label>
          </div>
        </div>

        <!-- Téléchargement des applications -->
        <div class="col-span-2 grid grid-cols-1">
          <ps-link
            textColor="text-textLight"
            url="https://play.google.com/store/apps/details?id=tg.mobile.meyaku"
          >
            <div class="p-1 rounded-lg">
              <img
                src="@/assets/images/androidapp.png"
                class="w-full cursor-pointer object-contain"
                alt="Télécharger l'application Android"
                style="height: 80px !important"
              />
            </div>
          </ps-link>
          <ps-link
            textColor="text-textLight"
            url="https://apps.apple.com/us/app/meyaku/id6737921738"
          >
            <div class="p-1 rounded-lg">
              <img
                src="@/assets/images/iosapp.png"
                class="w-full cursor-pointer object-contain"
                alt="Télécharger l'application IOS"
                style="height: 80px !important"
              />
            </div>
          </ps-link>
          <ps-label textColor="text-textLight text-xs" style="text-align:center"
          >&copy;2024 MEYAKU</ps-label
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import { ref } from "vue";
import router from "@/router";
import PsLabel from "@/components/core/label/PsLabel.vue";
import PsLink from "@/components/core/link/PsLink.vue";
import PsRouteLink from "@/components/core/link/PsRouteLink.vue";
import PsIcon from "@/components/core/icons/PsIcon.vue";

// Holders
import { PsValueProvider } from "@/store/modules/core/PsValueProvider";
import { useProductProviderState } from "@/store/modules/item/ProductProvider";
import { usePopularItemProviderState } from "@/store/modules/item/PopularItemProvider";

export default {
  components: {
    PsLabel,
    PsLink,
    PsRouteLink,
    PsIcon,
  },
  setup() {
    // Inject Item Provider
    const psValueHolder = PsValueProvider.psValueHolder;
    const locationId = ref(psValueHolder.locationId);

    // Inject Item Provider
    const itempopularProvider = usePopularItemProviderState();
    const itemrecentProvider = useProductProviderState();
    itempopularProvider.paramHolder.itemLocationId = locationId.value;
    function gotToHome() {
      router.push({ name: "dashboard" });
    }

    function viewAllRecentItemList() {
      const params = itemrecentProvider.paramHolder.getUrlParamsAndQuery();
      params["name"] = "item-list";
      router.push(params);
    }

    function viewAllPopularItemList() {
      const params = itempopularProvider.paramHolder.getUrlParamsAndQuery();
      params["name"] = "item-list";

      // Redirect
      router.push(params);
    }

    return {
      gotToHome,
      viewAllPopularItemList,
      viewAllRecentItemList,
    };
  },
};
</script>
